<template>
    <div class="d-flex">
        <small class="mr-1">Shipping Label:</small>
        <small @click="getShippingLabel('download')"
               :disabled="btnDisabled"
               style="cursor: pointer; color: #007bff;">
            Download
        </small>
        <small class="mx-1">|</small>
        <small @click="getShippingLabel('print')"
               :disabled="btnDisabled"
               style="cursor: pointer; color: #007bff;">
            Print
        </small>
    </div>
</template>

<script>
import { printShippingLabel } from '../../services/ShipmentService';

export default {
    name: 'ShipmentLabelButtons',
    props: {
        shipment: Object,
        rateId: String,
        shipmentId: Number,
        shipEngineId: String,
        items: Array,
        labelUrl: String,
        disabledOnLoad: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            downloading: false,
            printing: false
        };
    },
    computed: {
        btnDisabled() {
            if (this.disabledOnLoad) {
                return !this.rateId || this.showSpinner;
            }
            return this.showSpinner;
        },
        showSpinner() {
            return this.downloading || this.printing;
        }
    },
    methods: {
        async getShippingLabel(action) {
            if (this.labelUrl && action === 'download') {
                return this.downloadLabel(this.labelUrl);
            }
            this[`${action}ing`] = true;

            if (action === 'download') {
                this.downloadLabel(this.shipment.label_url);
            } else {
                try {
                    await printShippingLabel(
                        this.shipment.id,
                        action
                    )
                } catch (error) {
                    this.$emit('error-message', error.response.data.error);
                }
            }
        },
        downloadLabel(url) {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};
</script>
