<template>
    <div>
        <form @submit.prevent="getRates">
            <div class=" ml-0 mr-0 row d-flex align-items-center flat-rate-toggle-wrapper">
                <div class="custom-control custom-toggle col-md-2">
                    <input type="checkbox" :id="shipmentID" name="flatRateToggle" class="custom-control-input" @click="togglePackageFlatRate()" :disabled="gettingRates">
                    <label bel class="custom-control-label" :for="shipmentID">Flat Rate</label>
                </div>
                <div class="col-md d-flex align-items-center" v-if="isFlatRate">
                    <select v-model="package_code" class="custom-select" :disabled="gettingRates" required>
                        <option disabled value="">Select {{ carrier.name }} Package Type</option>
                        <option :value="option.package_code" v-for="option in flatRateOptions" :key="option.package_code">{{ option.name }}</option>
                    </select>
                </div>
                <div class="pl-0 mt-0 col-md input-group d-flex align-items-center" v-if="isFlatRate">
                    <span style="padding-left: 1rem">Max Weight: {{ package_code === "" ? "N/A" : package_code.includes('envelope') ? "10 lbs/package" : "50 lbs/package" }}</span>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <span class="mr-2">Packages</span>
                <span @click="addPackage" style="cursor: pointer">
                    <i class="text-success far fa-plus" />
                </span>
            </div>
            <ul class="list-group mt-2">
                <li v-for="(pkg, index) in packages"
                    :key="pkg.packageID"
                    class="ml-0 mr-0 package list-group-item row d-flex align-items-center">
                    <div class="pl-0 col-md input-group d-flex align-items-center">
                        <div class="input-group-prepend">
                            <span class="mr-3">Weight</span>
                        </div>
                        <div class="d-flex">
                            <input type="number"
                                   min="0.01"
                                   :max="maximumWeight"
                                   step=".01"
                                   placeholder="lbs"
                                   class="weight form-control form-control-sm"
                                   @focus="pkg.weight = pkg.weight > 0 ? pkg.weight : null"
                                   @blur="pkg.weight = pkg.weight || '0.00'"
                                   v-model="pkg.weight"
                                   :disabled="gettingRates"
                                   required>
                        </div>
                    </div>
                    <div class="col-md-5 input-group d-flex align-items-center" v-if="!isFlatRate">
                        <div class="input-group-prepend">
                            <span class="mr-3">Dimensions</span>
                        </div>
                        <div class="d-flex">
                            <input type="number"
                                   max="200"
                                   min="1"
                                   step="1"
                                   placeholder="Length"
                                   class="form-control form-control-sm"
                                   @focus="pkg.length = pkg.length > 0 ? pkg.length : null"
                                   @blur="pkg.length = pkg.length || 0"
                                   v-model="pkg.length"
                                   :disabled="gettingRates"
                                   required>
                            <input type="number"
                                   max="200"
                                   min="1"
                                   step="1"
                                   placeholder="Width"
                                   class="form-control form-control-sm"
                                   @focus="pkg.width = pkg.width > 0 ? pkg.width : null"
                                   @blur="pkg.width = pkg.width || 0"
                                   v-model="pkg.width"
                                   :disabled="gettingRates"
                                   required>
                            <input type="number"
                                   max="200"
                                   min="1"
                                   step="1"
                                   placeholder="Height"
                                   class="form-control form-control-sm"
                                   @focus="pkg.height = pkg.height > 0 ? pkg.height : null"
                                   @blur="pkg.height = pkg.height || 0"
                                   v-model="pkg.height"
                                   :disabled="gettingRates"
                                   required>
                        </div>
                    </div>
                    <div v-if="defaultDimensions.length && !isFlatRate"
                         class="ml-auto dropdown"
                         :class="{ 'mr-5': packages.length > 1 }">
                        <small class="text-secondary dropdown-toggle"
                               style="cursor: pointer;"
                               data-toggle="dropdown">
                            Defaults
                        </small>
                        <div class="dropdown-menu dropdown-menu-right">
                            <span v-for="dimensions in defaultDimensions"
                                  :key="dimensions.id"
                                  class="dropdown-item"
                                  style="cursor: pointer"
                                  @click="setDimensions(dimensions, index)">
                                {{ dimensions.display_name }}
                            </span>
                        </div>
                    </div>
                    <span v-if="packages.length > 1" @click="deletePackage(index)" class="mr-auto">
                        <i class="fal fa-times text-secondary" style="cursor: pointer" />
                    </span>
                </li>
            </ul>
            <div class="mt-3 d-flex">
                <span style="color: red;" v-if="error">{{ error }}</span>
                <button class="btn btn-sm btn-light ml-auto"
                        :disabled="gettingRates || getRatesDisabled || !shippingAddressIsValid">
                    <span v-if="gettingRates" class="spinner-border spinner-border-sm mr-2" />
                    Get rates
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import { getDefaultDimensions, getShippingRates } from '../../services/ShipmentService';
    import { getFlatRateOptions } from '../../services/ShipEngineService';

    export default {
        name: 'ShipmentPackages',
        props: {
            carrier: Object,
            getRatesDisabled: Boolean,
            shipmentCount: Number,
            gettingRates: Boolean,
            shippingAddress: Object,
            shippingAddressIsValid: {
                type: Boolean,
                default: true
            },
            selectedShipmentConfirmationTypeId: [Number, null]
        },
        data() {
            return {
                packages: [],
                defaultDimensions: [],
                emptyPackage: {
                    length: 0,
                    width: 0,
                    height: 0,
                    weight: '0.00',
                    packageID: 0,
                },
                isFlatRate: false,
                package_code: '',
                shipmentID: Math.random(),
                error: '',
                flatRateOptions: [],
                maximumDimensionSize: 200
            };
        },
        watch: {
            shipmentCount() {
                this.packages = [{ ...this.emptyPackage }];
            },
            packages: {
                handler: function() {
                    this.$emit('shipping-data-changed')
                },
                deep: true
            }
        },
        computed: {
            maximumWeight() {
                return this.package_code.includes('envelope') ? 10 : 150
            }
        },
        mounted() {
            this.getDefaultDimensions();
            this.addPackage();
            this.getFlatRates();
        },
        methods: {
            async getDefaultDimensions() {
                const response = await getDefaultDimensions();
                this.defaultDimensions = response.data;
            },
            addPackage() {
                this.packages.push({ ...this.emptyPackage, packageID: Math.random() });
            },
            deletePackage(index) {
                this.packages.splice(index, 1);
                this.$emit('deleted-package');
            },
            setDimensions(dimensions, index) {
                Object.keys(dimensions).forEach(dim => (this.packages[index][dim] = dimensions[dim]));
            },
            async getRates() {
                this.$emit('getting-rates', true);

                if (this.carrier.provider !== 'SE') {
                    return this.error = 'Rates can only be calculated for ShipEngine carriers.';
                }

                const packages = this.packages.map(pkg => ({
                    weight: {
                        value: parseFloat(pkg.weight),
                        unit: 'ounce',
                    },
                    dimensions: {
                        unit: 'inch',
                        length: parseFloat(pkg.length),
                        width: parseFloat(pkg.width),
                        height: parseFloat(pkg.height),
                    },
                }));

                try {
                    const response = await getShippingRates(
                        this.$route.name === 'quick-ship' ? this.shippingAddress : null,
                        this.$route.name === 'quick-ship' ? null : this.$route.params.id,
                        this.carrier.id,
                        packages,
                        this.package_code,
                        this.selectedShipmentConfirmationTypeId,
                    );
                    if (response.data.errors.length) {
                        this.error = response.data.errors[0].message;
                    } else {
                        this.error = '';
                        this.$emit('get-rates-data', response.data);
                    }
                } catch (error) {
                    console.log(error);
                }
                this.$emit('getting-rates', false);
            },
            async getFlatRates() {
                try {
                    const { data } = await getFlatRateOptions(this.carrier.shipengine_id);

                    const removedValues = ['fedex_10kg_box', 'fedex_25kg_box', 'package'];

                    this.flatRateOptions = data.packages.filter(pkg => !removedValues.includes(pkg.package_code));
                } catch (error) {
                    console.error(error);
                }
            },
            togglePackageFlatRate() {
                this.isFlatRate = !this.isFlatRate
                this.package_code = this.isFlatRate ? this.package_code : '';
            }
        }
    };
</script>

<style scoped>
    .package-list-item {
        border: 1px solid rgb(202, 199, 199);
        min-height: 50px;
        position: relative;
    }

    input.form-control {
        padding-left: 8px;
        width: 80px;
        padding-right: 5px;
    }

    input:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    input:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    .custom-toggle {
        padding-left: 0;
        display:inline-block;
        position: relative;
    }

    .custom-control-label {
        padding-left: 4rem;
    }

    .flat-rate-toggle-wrapper {
        margin-bottom: 1.25rem;
    }
</style>
