<template>
    <div v-if="carriers.length" class="d-flex flex-column">
        <div class="row">
            <div class="col-md-3">
                <div>Carrier</div>
                <div class="btn-group mt-2 mb-3">
                    <button v-for="carrier in carriers"
                            :key="carrier.id"
                            type="button"
                            class="btn btn-sm"
                            :class="carrier.id === selectedCarrier.id ? 'btn-secondary' : 'btn-outline-secondary'"
                            @click="emitCarrier(carrier)">
                        {{ carrier.name }}
                    </button>
                </div>
            </div>
        </div>
        <ShipmentConfirmationTypeDropdown v-if="selectedCarrier.shipment_confirmation_types && selectedCarrier.shipment_confirmation_types.length"
                                          :carrier="selectedCarrier"
                                          @updateSelectedShipmentConfirmationTypeId="$emit('updateSelectedShipmentConfirmationTypeId', $event)" />
    </div>
</template>

<script>
    import { getCarriers } from '@/services/ShipmentService';
    import ShipmentConfirmationTypeDropdown from '@/components/shipments/ShipmentConfirmationTypeDropdown';

    export default {
        name: 'ShipmentCarriers',
        components: {
            ShipmentConfirmationTypeDropdown
        },
        data() {
            return {
                carriers: [],
                selectedCarrier: {},
            };
        },
        async mounted() {
            try {
                const { data: carriers } = await getCarriers();
                this.carriers = carriers;

                if (this.carriers.length) {
                    // Move the FedEx carrier to the front if it's available.
                    const selectedCarrierIndex = this.carriers.findIndex(carrier => carrier.name === 'FedEx');
                    if (selectedCarrierIndex >= 0) {
                        this.carriers.unshift(this.carriers.splice(selectedCarrierIndex, 1)[0]);
                    }

                    // Select the first carrier.
                    this.selectedCarrier = this.carriers[0];
                }

                this.$emit('num-carriers', this.carriers.length);
                this.$emit('get-carrier', this.selectedCarrier);
            } catch (error) {
                console.log(error);
            }
        },
        methods: {
            emitCarrier(carrier) {
                this.selectedCarrier = carrier;
                this.$emit('get-carrier', carrier);
            },
        }
    };
</script>

<style scoped>
    .btn-secondary.focus,
    .btn-secondary:focus {
        box-shadow: none !important;
    }
</style>
