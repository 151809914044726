<template>
    <li class="list-group-item flex-fill p-0">
        <div class="d-flex flex-column" style="padding: 15px;">
            <b>{{ headerText }}</b>
            <ul class="list-group mt-2">
                <li class="list-group-item item-list-item"
                    style="cursor: pointer;"
                    v-for="(product, index) in Object.keys(groupedItems)"
                    :key="product"
                    data-toggle="collapse"
                    :data-target="`#${extra}-${index}`">
                    <div class="d-flex align-items-center">
                        {{ groupedItems[product].length }}x {{ product }}
                        <i class="ml-auto fa fa-caret-down text-secondary"/>
                    </div>
                    <OrderItemData :items="groupedItems[product]"
                                   class="collapse"
                                   :id="`${extra}-${index}`"/>
                </li>
            </ul>
        </div>
    </li>
</template>

<script>
import _ from 'lodash';
import OrderItemData from '../../components/orders/OrderItemData.vue';

export default {
    name: 'ItemList',
    components: {
        OrderItemData
    },
    props: {
        items: Array,
        extra: Boolean
    },
    computed: {
        groupedItems() {
            return _.groupBy(this.items, item => item.product_name);
        },
        headerText() {
            if (this.$route.name === 'quick-ship') {
                return ''
            } else {
                if (this.extra) {
                    return 'Extra items'
                } else {
                    return 'Items in Big Commerce order'
                }
            }
        }
    }
};
</script>

<style scoped>
    .item-list-item {
        border-left: none;
    }
</style>