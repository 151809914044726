<template>
    <div>
        <div class="mb-2">
            Delivery Confirmation Type
        </div>
        <div v-if="carrier.shipment_confirmation_types.length">
            <div class="dropdown">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle px-2" type="button"
                        data-toggle="dropdown">
                    {{ selectedShipmentConfirmationType.name }}
                </button>
                <div class="dropdown-menu p-0">
                    <ul class="list-group">
                        <li v-for="type in carrier.shipment_confirmation_types"
                            :key="type.id"
                            class="list-group-item px-2 py-0"
                            style="white-space: normal;">
                            <div class="form-check d-flex align-items-center">
                                <input @change="updateSelectedShipmentConfirmationTypeId(type.id)"
                                       :checked="selectedShipmentConfirmationTypeId === type.id"
                                       type="radio"
                                       class="form-check-input"
                                       :id="`check${type.id}`">
                                <label class="form-check-label py-2" :for="`check${type.id}`">
                                    <small><strong>{{ type.name }}</strong> - {{ type.description }}</small>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-else>
            Selected carrier does not have any configured delivery confirmation types. Please contact an admin for
            further assistance.
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShipmentConfirmationTypeDropdown',
    props: {
        carrier: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            selectedShipmentConfirmationTypeId: null
        }
    },
    computed: {
        selectedShipmentConfirmationType() {
            if (this.selectedShipmentConfirmationTypeId) {
                return this.carrier.shipment_confirmation_types.find(type => type.id === this.selectedShipmentConfirmationTypeId);
            } else {
                return {};
            }
        }
    },
    mounted() {
        this.selectedShipmentConfirmationTypeId = this.carrier.shipment_confirmation_types[0]?.id || null;
    },
    methods: {
        updateSelectedShipmentConfirmationTypeId(id) {
            this.selectedShipmentConfirmationTypeId = id;
            this.$emit('updateSelectedShipmentConfirmationTypeId', id);
        }
    }
}
</script>
