import { render, staticRenderFns } from "./ShipmentRates.vue?vue&type=template&id=20fba800&scoped=true&"
import script from "./ShipmentRates.vue?vue&type=script&lang=js&"
export * from "./ShipmentRates.vue?vue&type=script&lang=js&"
import style0 from "./ShipmentRates.vue?vue&type=style&index=0&id=20fba800&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20fba800",
  null
  
)

export default component.exports