<template>
    <div v-if="rates.length">
        <hr/>
        <b>Shipping rates</b>
        <small class="ml-2" v-if="!!customerPaidText">({{ customerPaidText }})</small>
        <ul class="mt-3 list-group">
            <li v-for="rate in rates"
                :key="rate.rate_id"
                class="list-group-item rates d-flex align-items-center"
                @click="updateSelectedRateId(rate.rate_id)">
                {{ rate.service_type }}
                <span class="ml-auto d-flex align-items-center">
                    ${{ formatCost(rate.shipping_amount.amount) }}
                    <span :style="{ color: getCheckColor(rate.rate_id) }">
                        <i class="ml-3 fa fa-check-circle"/>
                    </span>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import numeral from 'numeral';
import ShipmentLabelButtons from '../../components/shipments/ShipmentLabelButtons.vue';

export default {
    name: 'ShipmentRates',
    components: {
        ShipmentLabelButtons
    },
    props: {
        rates: Array,
        shipEngineId: String,
        customerPaidText: String,
        items: Array,
        selectedRateId: String
    },
    watch: {
        rates() {
            this.updateSelectedRateId(null);
        }
    },
    methods: {
        formatCost(cost) {
            return numeral(cost).format('0,0.00');
        },
        getCheckColor(id) {
            return this.selectedRateId === id ? '#1dc671 !important' : '#e0e0e0 !important';
        },
        updateSelectedRateId(rateId) {
            this.$emit('update-rate-id', rateId);
        }
    }
};
</script>

<style scoped>
.rates {
    cursor: pointer;
}

.rates:hover {
    background: #f5f5f5;
}

.fa-plus-circle {
    width: 12px;
    padding-top: 2px;
}
</style>