<template>
    <div>
        <hr class="my-3" />
        <div style="overflow-x: auto !important">
            <table class="table table-sm table-borderless table-responsive mb-0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Import date</th>
                        <th>UUID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>{{ formatDate(item.created_at) }}</td>
                        <td>{{ item.uuid }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'OrderItemData',
    props: {
        items: Array
    },
    computed: {
        fields() {
            const fields = [];
            this.items[0].field_data.forEach(field => fields.push(field.field_name));
            return fields;
        }
    },
    methods: {
        getFieldData(item, field) {
            const fieldData = item.field_data.find(data => data.field_name === field);
            if (fieldData) {
                return fieldData.field_is_date ? this.formatDate(fieldData.data) : fieldData.data;
            }
        },
        formatDate(date) {
            return moment(date).format('ll');
        }
    }
};
</script>

<style scoped>
.table-sm td,
.table-sm th {
    padding-right: 1rem;
    padding-left: 0;
}

.table-sm {
    width: inherit !important;
    font-size: 80%;
}
</style>