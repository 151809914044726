import axios from 'axios';

export const getShipments = () => {
    return axios.get('shipments/');
};

export const getShipment = id => {
    return axios.get(`shipments/${id}/`);
};

export const createManualShipment = (orderId, carrier) => {
    return axios.post(`orders/${orderId}/shipments/`, { carrier });
};

/**
 * Gets shipping rates from ShipEngine for a shipment with the provided carrier and packages.
 *
 * @param {Object|null} ship_to - An address to ship to.
 * @param {number|null} order_id - The ID of an Order that contains shipping address in BigCommerce.
 * @param {number} carrier_id - The selected carrier to ship with.
 * @param {Object[]} packages - The contents of a Shipment.
 * @param {string} package_code - ShipEngine flat-rate code.
 * @param {number|null} shipment_confirmation_type_id - The ID of a ShipmentConfirmationType.
 */
export const getShippingRates = (ship_to, order_id, carrier_id, packages, package_code, shipment_confirmation_type_id) => {
    return axios.post('shipments/rates/', {
        carrier_id,
        packages,
        package_code,
        ...(ship_to && { ship_to }),
        ...(order_id && { order_id }),
        ...(shipment_confirmation_type_id && { shipment_confirmation_type_id })
    });
};

export const createShipment = (shipengine_id, rate_id, carrier_id, order_id, order_status, items) => {
    return axios.post('shipments/', { shipengine_id, rate_id, carrier_id, order_id, order_status, items });
};

export const printShippingLabel = (shipment_id, action) => {
    return axios.post('shipments/labels/', { shipment_id, action });
};

export const getCarriers = () => {
    return axios.get('carriers/');
};

export const getDefaultDimensions = () => {
    return axios.get('packages/');
};

export const addPackageDimensions = dimensions => {
    return axios.post('packages/', dimensions);
};

export const deletePackageDimensions = id => {
    return axios.delete(`packages/${id}/`);
};
